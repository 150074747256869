@keyframes blink {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.App {
  font-family: Arial;
  font-size: 14px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.flex-hc-vc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-vc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-hs-vc {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

/* navigation */

#nav-bar {
  justify-content: flex-end;
}

.spacer{
  height: 56px;
}

#logo{
  color: grey;
  font-weight: 600;
}

.profile-btn {
  background-color: #f8f9fa;
  color: #00000080;
  border: none;
  padding: 8px 0;
}
.profile-btn:focus{
  outline: none;
}
#profile-btn-text {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}

/* login form */

.popup-container {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  background-attachment: fixed;
}

#login-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 20px;
  color: #5a5a5a;
  font-weight: bold;
  font-size: 14px;
}

#login-box-btngroup {
  width: 100%;
  margin-top: 10px;
}
#register-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 20px;
  color: #5a5a5a;
  font-weight: bold;
  font-size: 14px;
  min-width: 300px;
}

#register-box-btngroup {
  width: 100%;
  margin-top: 10px;
}

.hint {
  color: white;
  background-color: rgb(238, 121, 121);
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

.loading {
  position: absolute;
  z-index: 1032;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* homepage cover section */

#home-cover {
  height: calc(100vh - 56px);
  min-height: 600px;
  max-height: 800px;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

#home-cover-title {
  height: calc(100vh - 56px);
  padding: 60px;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
}

#home-cover-title button {
  font-weight: 600;
  margin-top: 20px;
}

#home-cover-img {
  height: calc(100vh - 56px);
  width: 100%;
  background-image: url('https://static.eileen-yu.com/eileen-cover.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* home page cover list section */

#cover-list {
  position: relative;
  margin-bottom: 20px;
}

#scroll-btn {
  position: absolute;
  bottom: 6vh;
  left: calc(50% - 30px);
  width: 60px;
  padding: 2px;

  color: white;
  font-weight: 600;

  background-color: rgba(0,0,0,0.2);
  transition: background-color 0.2s;

  border-top: white solid 2px;
  border-radius: 0 0 4px 4px;
}

#scroll-btn:hover {
  background-color:rgba(0,0,0,0.6);
  cursor: pointer;
}

#icon-scroll {
  background-image: url("https://static.eileen-yu.com/icon/scroll-white.png");
  animation: blink 3s infinite;
}

.title {
  font-weight: bold;
  color: #5a5a5a;
}

.title-section {
  font-size: 16px;
  margin: auto;
  margin: 0 16px;
  width: fit-content;
}

.title-overlay {
  color: white;
  text-shadow: 1px 1px 4px black;
  font-size: 24px;
  font-weight: 800;
  margin: 0 40px;
}

.text-c {
  text-align: center;
}

.text-r {
  text-align: right;
}

.text-l {
  text-align: left;
}

#cover-1 {
  background-image: url('https://static.eileen-yu.com/eileen-cover.jpg');
  height: calc(100vh - 56px);
  transition: height 0.5s;
  min-height: 305px;
  max-width: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#cover-2 {
  background-image: url('https://static.eileen-yu.com/eileen-cover2.jpg');
  height: calc(100vh - 56px);
  transition: height 0.5s;
  min-height: 305px;
  max-width: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#cover-3 {
  background-image: url('https://static.eileen-yu.com/meal.jpg');
  height: calc(100vh - 56px);
  transition: height 0.5s;
  max-width: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* home page program list */

.program-list {
  max-width: 1140px;
  width: 90%;
  margin-top: 20px;
  background-color: white;
  box-shadow: 4px 4px 10px rgb(209, 209, 209);
}

.program-list-single {
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.program-list-single-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.2s;
}

.program-list-single-overlay:hover {
  background-color: rgba(0,0,0,0.2);
}

.program-list-single-cover {
  width: 100%;
  border-radius: 6px;
  /* max-width: 450px; */
}

.program-list-single-info {
  padding: 30px 10px 10px 10px;
  color: #5a5a5a;
  width: 100%;
}

.program-list-single-info h3 {
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  border-bottom: rgb(214, 214, 214) solid 2px;
}

.program-list-single-desc {
  width: 100%;
  text-align: left;
}

.badge-new{
  background-color: #28a745;
  color: white;
  padding: 1px 3px;
  font-size: 12px;
  border-radius: 2px;
}

.program-list-single-info p {
  margin-top: 6px;
}

.program-list-single-span {
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  color: rgb(180, 180, 180);
}

/* faq section */

.faq-list{
  max-width: 1140px;
  width: 90%;
  margin-top: 20px;
  /* background-color: white; */
  background-color: (to right,grey, white);
  box-shadow: 4px 4px 10px rgb(209, 209, 209);
  color: #5a5a5a;
}

.faq-list-single-header {
  margin-bottom: 0;
  margin-top: 2px;
}

.faq-list-single-header button {
  border: none;
  color:rgb(87, 87, 87);
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  text-align: left;
  padding: 12px 24px;
  background-color: rgb(246, 240, 218);
  /* background-image: linear-gradient(to top,rgb(246, 240, 218), white); */
}

.faq-list-single-body {
  padding: 8px 30px 16px 30px;
  line-height: 24px;
  font-size: 14px;
  /* background-color: white; */
  background-image: linear-gradient(to bottom,rgb(246, 240, 218), white);

}

/* contact section */

#footer-navbar-collapse {
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 1140px;
  width: 100%;
}
#footer-navbar-links{
  margin-left: 10px;
}

.iconfont {
  font-size: 24px;
}

.icon {
  height: 80px;
  width: 80px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px;
}

.icon-btn {
  height: 50px;
  width: 80px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  margin: 10px;
  border-radius: 4px;
  border: solid white 4px;
  box-shadow: 2px 2px 4px black;
}

.icon-btn:hover{
  background-color: rgb(199, 199, 199);
  border-color: rgb(199, 199, 199);
}

.smicon-link {
  padding: 2px;
  border-radius: 6px 6px 0 0;
}

.smicon-link-selected {
  background-color: grey;
}

.icon-small {
  height: 16px;
  width: 16px;
  margin-bottom: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-mid {
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.relax {
  background-image: url("https://static.eileen-yu.com/icon/relax.png");
}
.icon-email {
  background-image: url("https://static.eileen-yu.com/icon/email.png");
}
.icon-youtubesmall {
  background-image: url("https://static.eileen-yu.com/icon/youtubesmall.png");
}
.icon-youtube {
  background-image: url("https://static.eileen-yu.com/icon/youtube.png");
}
.icon-bili {
  background-image: url("https://static.eileen-yu.com/icon/bili.png");
}
.icon-bilismall {
  background-image: url("https://static.eileen-yu.com/icon/bilismall.png");
}
.icon-instagram {
  background-image: url("https://static.eileen-yu.com/icon/instagram.png");
}
.icon-weibo {
  background-image: url("https://static.eileen-yu.com/icon/weibo.png");
}
.icon-xigua {
  background-image: url("https://static.eileen-yu.com/icon/xigua.png");
}
.icon-wechat {
  background-image: url("https://static.eileen-yu.com/icon/wechat.png");
}
.icon-calendar {
  background-image: url("https://static.eileen-yu.com/icon/calendar.png");
}

#sm-display {
  width: 100vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#sm-display-img {
  width: 100%;
  max-width: 400px;
}
#sm-btn {
  width: 80%;
  max-width: 300px;
  margin: 10px 0;
}

#copyright {
  padding-left: 10px;
  font-size: small;
}

.popover-contact {
  width: fit-content;
}

.contact-img {
  width: 100px;
}

/* program single page */

.program-single-page {
  min-height: 80vh;
  font-style: italic;

  /* test */
  /* background-color: #F9F8E6; */
}

.program-single-banner-bg {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.program-single-banner {
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  top: 56px;

  color: white;
  /* padding: 10px; */
}

.program-single-infobox {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  padding: 10px;
}

.program-single-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: solid white 2px;
}

.program-single-calendar {
  /* display: flex;
  flex-direction: column; */
  padding: 20px 10px;
  padding: 10px;
  max-width: 1200px;
  /* background-color: darkgray; */
}

.program-single-day {
  display: flex;
  flex-direction: column;
  padding: 10px;

  /* test */
  border-left: rgb(87, 87, 87) solid 2px;
}

.program-single-day-title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 15px 0;
  position: relative;

  /* test */
  /* background-color: #89937A; */
}

.program-single-day-dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: rgb(87, 87, 87);
  left: -15px;
  top: 30px;
  border-radius: 50%;
}

.program-single-day-time {
  color: rgb(87, 87, 87);
  padding: 10px 20px;
  /* background-color: #fa6f6f; */
}

.program-single-day-title {
  /* color: khaki; */
  padding: 5px 15px;
  display: flex;
  align-items: center;
  color: rgb(87, 87, 87);
  font-size: 16px;
}

.program-single-detail {
  font-size: 12px;
}

.program-single-day-title-txt {
  font-weight: 600;
}

.program-single-day-detail {
  font-weight: 800;
  padding-left: 8px;
}

.program-single-day-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  min-height: 200px;
}

.program-single-box-wrapper {
  margin: 5px;
  box-shadow: 2px 2px 8px grey;
  border-radius: 4px;
  overflow: hidden;
}

.program-single-box {
  width: 80vw;
  max-width: 360px;
  min-width: 240px;
  height: 45vw;
  max-height: 202.5px;
  min-height: 135px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-shadow: 1px 1px 4px black;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
}

.program-single-box-icon {
  margin-left: 12px;
}

.program-single-box-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.program-single-box-title-container {
  /* height: 100%; */
  width: 100%;
  background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.7));
  font-size: 14px;
  padding: 6px 12px;
  text-align: end;
}

.program-single-box-btngroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  /* background-color: grey;s */
  /* background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.7)); */
}

.icon-btn-mobile {
  height: 34px;
  width: 50%;
  background-position: center;
  background-size: auto 14px;
  background-repeat: no-repeat;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.icon-btn-mobile:hover{
  background-color: rgba(255, 255, 255, 0.7)
}

/* construction page */

.construction-page {
  width: 100vw;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative
}

#construction-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 680px) {
  .profile-btn {
    padding: 8px;
  }

  .program-list-single {
    flex-direction: row;
  }

  .program-list-single-cover {
    width: 34%;
    max-width: 400px;
  }

  .program-list-single-info {
    width: 66%;
    padding: 40px;
  }
}


@media (min-width: 540px) {
  .App {
    font-size: 14px;
  }

  #home-cover-title {
    width: 50%;
    position: static;
    background-color: #f8f9fa;
    color: #5a5a5a;
    font-size: 22px;
    line-height: 36px;
  }

  #home-cover-img {
    width: 50%;
  }

  .program-single-box-btngroup {
    display: none;
  }


}